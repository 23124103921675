import { render, staticRenderFns } from "./EatAndSleepScreen.vue?vue&type=template&id=fe1f5cae&scoped=true&"
import script from "./EatAndSleepScreen.vue?vue&type=script&lang=js&"
export * from "./EatAndSleepScreen.vue?vue&type=script&lang=js&"
import style0 from "./EatAndSleepScreen.vue?vue&type=style&index=0&id=fe1f5cae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe1f5cae",
  null
  
)

export default component.exports